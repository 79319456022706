import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "@/store";

Vue.use(VueRouter)

const routes = [
    {
        path: '*',
        name: 'notFound',
        component: () => import('./views/notFound'),
        meta: {
            title: 'Page non trouvée'
        }
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('./views/auth/login'),
        meta: {
            title: 'Connexion'
        }
    },
    {
        path: '/register',
        name: 'register',
        component: () => import('./views/auth/register'),
        meta: {
            title: 'Inscription pos'
        }
    },
    {
        path: '/account',
        name: 'account',
        component: () => import('./views/account/index'),
        meta: {
            title: 'Mon compte',
        }
    },
    {
        path: '/delete-account',
        component: () => import('./views/deleteAccount.vue'),
        meta: {
            title: 'Supprimer mon compte TIP',
        }
    },

    ////////////////////////////dashboard////////////////////////
    {
        path: '/',
        name: 'dashboard',
        component: () => import('./views/dashboard/index'),
        meta: {
            title: 'Dashboard',
            permissions: ['Voir la page dashboard'],
        }
    },

    ////////////////////////////campaigns////////////////////////
    {
        path: '/campaigns',
        name: 'campaigns',
        component: () => import('./views/campaigns/index/index'),
        meta: {
            title: 'Campagnes',
            permissions: ['Voir la page campagnes', 'Ajouter une campagne', 'Modifier une campagne', 'Supprimer une campagne'],
        }
    },
    {
        path: '/campaigns/task/:id/edit',
        name: 'task.update',
        component: () => import('./views/campaigns/index/duplicate&updateTask/index'),
        meta: {
            title: 'Campagnes - Modifier une tâche',
            permissions: ['Modifier une campagne'],
        }
    },
    {
        path: '/campaigns/task/:id/duplicate',
        name: 'task.duplicate',
        component: () => import('./views/campaigns/index/duplicate&updateTask/index'),
        meta: {
            title: 'Campagnes - Dupliquer une tâche',
            permissions: ['Ajouter une campagne'],
        }
    },
    {
        path: '/campaigns/:id/add-tasks',
        name: 'campaigns.tasks.create',
        component: () => import('./views/campaigns/index/addTasks/index'),
        meta: {
            title: 'Campagnes - Ajouter des tâches',
            permissions: ['Ajouter une campagne'],
        }
    },
    {
        path: '/campaigns/:id/calendar',
        name: 'campaigns.calendar',
        component: () => import('./views/campaigns/index/calendar'),
        meta: {
            title: 'Campagnes - Calendrier',
            permissions: ['Voir la page campagnes'],
        }
    },
    {
        path: '/campaigns/stepper/info&planning',
        name: 'campaigns-info-planning',
        component: () => import('./views/campaigns/stepper/info&planning'),
        meta: {
            title: 'Campagnes | Info & Planification',
            permissions: ['Ajouter une campagne'],
        }
    },
    {
        path: '/campaigns/stepper/tasks',
        name: 'campaigns-tasks',
        component: () => import('./views/campaigns/stepper/tasks'),
        meta: {
            title: 'Campagnes | Ajouter des tâches',
            permissions: ['Ajouter une campagne'],
        }
    },
    {
        path: '/campaigns/:id/analytics',
        name: 'analytics',
        component: () => import('./views/analytics/index'),
        meta: {
            title: 'Analyses',
            permissions: ['Voir la page utilisateurs', 'Ajouter un utilisateur', 'Modifier un utilisateur', 'Supprimer un utilisateur'],
        }
    },

    ////////////////////////////backoffice////////////////////////
    {
        path: '/backoffice',
        name: 'backoffice.tempValidation',
        component: () => import('./views/backoffice/temp-validation'),
        meta: {
            title: 'Backoffice - validation temporaire',
            permissions: ['Validation temperaire'],
        }
    },
    {
        path: '/backoffice/final-validation',
        name: 'backoffice.finalValidation',
        component: () => import('./views/backoffice/final-validation'),
        meta: {
            title: 'Backoffice - validation finale',
            permissions: ['Validation finale'],
        }
    },
    {
        path: '/backoffice/final-validation/:id',
        name: 'backoffice.finalValidation.tasks',
        component: () => import('./views/backoffice/final-validation/responses'),
        meta: {
            title: 'Backoffice - validation finale',
            permissions: ['Validation finale'],
        }
    },
    {
        path: '/backoffice/completed',
        name: 'backoffice.completed',
        component: () => import('./views/backoffice/completed/index'),
        meta: {
            title: 'Backoffice - terminées',
            permissions: ['Terminées'],
        }
    },
    {
        path: '/backoffice/completed/:id',
        name: 'backoffice.completed.tasks',
        component: () => import('./views/backoffice/completed/responses'),
        meta: {
            title: 'Backoffice - terminées',
            permissions: ['Terminées'],
        }
    },

    ////////////////////////////conversions////////////////////////
    {
        path: '/conversions',
        name: 'conversions',
        component: () => import('./views/transactions/index'),
        meta: {
            title: 'Conversions',
            permissions: ['Voir la page conversions', 'Ajouter une conversion', 'Modifier une conversion', 'Voir la page statistiques'],
        }
    },

    {
        path: '/excel-conversions',
        name: 'excel.conversions',
        component: () => import('./views/excelTransactions/index'),
        meta: {
            title: 'Conversions par excel',
            permissions: ['Voir la page conversions', 'Ajouter une conversion', 'Modifier une conversion', 'Voir la page statistiques'],
        }
    },

    ////////////////////////////gestion de produit////////////////////////
    {
        path: '/products',
        name: 'products',
        component: () => import('./views/products/products/index'),
        meta: {
            title: 'Produits',
            permissions: ['Voir la page produits', 'Gestion des produits'],
        }
    },
    {
        path: '/products/categories',
        name: 'categories',
        component: () => import('./views/products/categories/index'),
        meta: {
            title: 'Catégories',
            permissions: ['Voir la page produits', 'Gestion des produits'],
        }
    },
    {
        path: '/products/brands',
        name: 'brands',
        component: () => import('./views/products/brands/index'),
        meta: {
            title: 'Marques',
            permissions: ['Voir la page produits', 'Gestion des produits'],
        }
    },

    ////////////////////////////database////////////////////////
    {
        path: '/database',
        name: 'database',
        component: () => import('./views/database/index'),
        meta: {
            title: 'Base de données',
            permissions: ['Voir la page BDD', 'Exporter la BDD', 'Importer une BDD', 'Ajouter une POS', 'Modifier une POS', 'Supprimer une POS'],
        }
    },

    ////////////////////////////gestion users////////////////////////
    {
        path: '/users',
        name: 'users',
        component: () => import('./views/users/index'),
        meta: {
            title: 'Utilisateurs',
            permissions: ['Voir la page utilisateurs', 'Ajouter un utilisateur', 'Modifier un utilisateur', 'Supprimer un utilisateur'],
        }
    },
    {
        path: '/users/roles',
        name: 'users.roles',
        component: () => import('./views/users/roles/index'),
        meta: {
            title: 'Utilisateurs - roles',
            permissions: ['Voir la page roles', 'Ajouter un rôle', 'Modifier un rôle', 'Supprimer un rôle'],
        }
    },

    ///////////log///////////
    {
        path: '/log',
        name: 'log',
        component: () => import('./views/log'),
        meta: {
            title: 'Logs',
            // permissions: ['Voir la page errous'],
        }
    },

    //////////GIFTY////
    {
        path: '/gifty',
        name: 'gifty',
        component: () => import('./views/gifty/index'),
        meta: {
            title: 'Gifty',
        }
    },

    ////////////////////////////Supervisor campaigns////////////////////////
    {
        path: '/KPIs',
        name: 'KPIs',
        component: () => import('./views/ASMCampaigns/KPIs/index.vue'),
        meta: {
            title: 'KPIs',
            permissions: ['Voir la page campagnes', 'Ajouter une campagne', 'Modifier une campagne', 'Supprimer une campagne'],
        }
    },
    {
        path: '/asm-campaigns',
        name: 'asm-campaigns.index',
        component: () => import('./views/ASMCampaigns/index'),
        meta: {
            title: 'Campagnes ASM',
            permissions: ['Voir la page campagnes', 'Ajouter une campagne', 'Modifier une campagne', 'Supprimer une campagne'],
        }
    },
    {
        path: '/asm-campaigns/create',
        name: 'asm-campaigns.create',
        component: () => import('./views/ASMCampaigns/create.vue'),
        meta: {
            title: 'Campagnes ASM - Ajouter une campagne',
            permissions: ['Ajouter une campagne', 'Modifier une campagne'],
        }
    },
    {
        path: '/asm-campaigns/:id/edit',
        name: 'asm-campaigns.edit',
        component: () => import('./views/ASMCampaigns/create.vue'),
        meta: {
            title: 'Campagnes ASM - Modifier une campagne',
            permissions: ['Ajouter une campagne', 'Modifier une campagne'],
        }
    },

    {
        path: '/supervisors',
        name: 'supervisors',
        component: () => import('./views/supervisors/index'),
        meta: {
            title: 'Comptes ASM',
            permissions: ['Voir la page campagnes', 'Ajouter une campagne', 'Modifier une campagne', 'Supprimer une campagne'],
        }
    },

    /**
     * Statement Campaigns
     */

    {
        path: '/statement-campaigns',
        name: 'statement-campaigns.index',
        component: () => import('./views/statementCampaigns/index'),
        meta: {
            title: 'Campagnes',
            permissions: ['Jeux concours'],
        }
    },
    {
        path: '/statement-campaigns/create',
        name: 'statement-campaigns.create',
        component: () => import('./views/statementCampaigns/create.vue'),
        meta: {
            title: 'Campagnes - Ajouter une campagne',
            permissions: ['Jeux concours'],
        }
    },
    {
        path: '/statement-campaigns/:id/edit',
        name: 'statement-campaigns.edit',
        component: () => import('./views/statementCampaigns/create.vue'),
        meta: {
            title: 'Campagnes - Modifier une campagne',
            permissions: ['Jeux concours'],
        }
    },
    {
        path: '/wholesalers',
        name: 'wholesalers',
        component: () => import('./views/wholesalers/index'),
        meta: {
            title: 'Grossistes',
            permissions: ['Jeux concours'],
        }
    },
    {
        path: '/qr-codes',
        name: 'qr-codes',
        component: () => import('./views/statementCampaigns/qrCodes/index'),
        meta: {
            title: 'Codes QR',
            permissions: ['Jeux concours'],
        }
    },
    {
        path: '/statements',
        name: 'statements',
        component: () => import('./views/statementCampaigns/statements/index'),
        meta: {
            title: 'Jeux concours',
            permissions: ['Jeux concours'],
        }
    },

    /**
     * Raffle Campaigns
     */

    {
        path: '/raffle-campaigns',
        name: 'raffle-campaigns.index',
        component: () => import('./views/raffleCampaigns/index'),
        meta: {
            title: 'Campagnes',
            permissions: ['Tombola'],
        }
    },
    {
        path: '/raffle-campaigns/create',
        name: 'raffle-campaigns.create',
        component: () => import('./views/raffleCampaigns/create.vue'),
        meta: {
            title: 'Campagnes - Ajouter une campagne',
            permissions: ['Tombola'],
        }
    },
    {
        path: '/raffle-campaigns/:id/edit',
        name: 'raffle-campaigns.edit',
        component: () => import('./views/raffleCampaigns/create.vue'),
        meta: {
            title: 'Campagnes - Modifier une campagne',
            permissions: ['Tombola'],
        }
    },
    {
        path: '/raffle-campaigns/:id/draw',
        name: 'draw',
        component: () => import('./views/raffleCampaigns/draw.vue'),
        meta: {
            title: 'Campagnes - Tirage au sort',
            permissions: ['Tombola'],
        }
    },
    {
        path: '/raffle-wholesalers',
        name: 'raffle.wholesalers',
        component: () => import('./views/wholesalers/index'),
        meta: {
            title: 'Grossistes',
            permissions: ['Tombola'],
        }
    },
    {
        path: '/raffle-qr-codes',
        name: 'raffle-qr-codes',
        component: () => import('./views/raffleCampaigns/qrCodes/index'),
        meta: {
            title: 'Codes QR',
            permissions: ['Tombola'],
        }
    },
    {
        path: '/raffle-participations',
        name: 'raffle.participations',
        component: () => import('./views/raffleCampaigns/participations/index'),
        meta: {
            title: 'Participations',
            permissions: ['Tombola'],
        }
    },
    /***
     *
     */
    {
        path: '/deletion-requests',
        name: 'deletionRequests',
        component: () => import('./views/deletionRequests/index.vue'),
        meta: {
            title: 'Demandes de suppression',
            permissions: ['Voir la page dashboard'],
        }
    },
    {
        path: '/gifty-liaisons',
        name: 'gifty.liaisons',
        component: () => import('./views/giftyLiaisons'),
        meta: {
            title: 'Liaisons',
            permissions: ['Voir la page BDD'],
        }
    },
    {
        path: '/link-qrcodes',
        component: () => import('./views/linkQrCodes'),
        meta: {
            title: 'Qr codes de liaison',
            permissions: ['Voir la page BDD'],
        }
    },

]
const router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior: (to, from, savedPosition) => {
        if (savedPosition) {
            return savedPosition;
        } else if (to.hash) {
            return {
                selector: to.hash
            };
        } else {
            return {x: 0, y: 0};
        }
    }
})

//Auth
router.beforeEach((to, from, next) => {
    const publicPages = ['/login', '/register', '/delete-account'];
    const authRequired = !publicPages.includes(to.path);
    const loggedIn = store.state.isLoggedIn;
    if (authRequired && !loggedIn) {
        next('/login');
    } else if (!authRequired && loggedIn) {
        next('/');
    } else {
        next()
    }
});

//Page title
router.beforeEach((to, from, next) => {
    document.title = process.env.VUE_APP_NAME + '::' + to.meta.title
    next()
});

//Permissions
router.beforeEach((to, from, next) => {
    if (to.meta.permissions) {
        let state = false
        let permissions = to.meta.permissions
        permissions.map(function (el) {
            store.state.permissions.includes(el) ? state = true : false
        })
        if (state) {
            next();
        } else {
            next('/404');
        }
    } else {
        next()
    }
})

export default router
